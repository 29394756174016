import React from 'react';

class NotFound extends React.Component {
  render() {
    return (
      <h1>404: Page not Found</h1>
    );
  }
}

export default NotFound;
